<template>
  <div class="body">
    <ul>
      <li v-for="item in dataList" :key="item.mobile">
        <span>手机号：</span>{{ item.mobile }}<br>
        <span>订单号：</span>{{ item.sn }}<br>
        <span>姓名：</span>{{ item.user_name }}<br>
        <span>产品：</span>{{ item.product_name }}<br>
        <span>到期日期：</span>{{ item.due_date }}<br>
        <span>贷款金额：</span>{{ item.apply_amt }}<br>
        <span>应还金额：</span>{{ item.all_amt }}<br>
      </li>
      <li v-if="!dataList.length">暂无数据</li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      dataList:[]
    }
  },
  created() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const mobileValue = params.get('mobile');
    console.log(mobileValue);
    // if(!mobileValue) return
    axios.get(`/api/ai/detail?mobile=${mobileValue}`)
    .then(response => {
      console.log('数据：',response)
      this.dataList = response.data.data;
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.body {
  padding: 10px 40px;
}
li {
  list-style: none;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 20px;
  border-bottom: 1px solid #606266;
  margin-top: 20px;
}
span {
  width: 70px;
  display: inline-block;
}
</style>
